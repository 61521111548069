import React, { useState } from 'react';
import Layout from '../components/Layout'
import { motion, useCycle } from "framer-motion"
import { useSpring, animated } from 'react-spring';
import { Link } from 'gatsby'
import MouseTooltip from 'react-sticky-mouse-tooltip';

import testVid from '../videos/testr.webm'


const Code = ({location}) => {
    const variants = { visible: { opacity: 1, y: 0 }, hidden: { opacity: 0, y: -30},}
    const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
    const trans4 = (x, y) => `translate3d(${x / 80}px,${y / 25}px,0)`
    const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))

    //tooltips
    var [visible, setVisible] = useState(false);
    var [waiverVisible, setWaiverVisible] = useState(false);
    var [covidVisible, setCovidVisible] = useState(false);
    function showFirstAid() { setVisible(true); document.getElementById('firstAidVid').style.display = 'block';};
    function hideFirstAid() { setVisible(false); document.getElementById('firstAidVid').style.display = 'none';};
    function showWaiver() { setWaiverVisible(true); document.getElementById('waiverVid').style.display = 'block';};
    function hideWaiver() { setWaiverVisible(false); document.getElementById('waiverVid').style.display = 'none';};
    function showCovid() { setCovidVisible(true); document.getElementById('covidVid').style.display = 'block';};
    function hideCovid() { setCovidVisible(false); document.getElementById('covidVid').style.display = 'none';};

    return (
        <Layout>
            <style>
                {'main,html { background-color: #FFF3C4; color: #1E3C25; }'}
                {'.line { background-color: #F05983; }'}
                {'a { color: #1E3C25; }'}
                {'a:hover { color: #FFF3C4; }'}
                {'a, a:link { background-image: linear-gradient( #FFF3C4 50%, #1E3C25 50%); }'}
                {'.cursor {background-color: #1E3C25}'}
                {'.cursor--link-hovered {background-color: #FFF3C4}'}
            </style>

             <div class="background" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>

                <motion.div
                    animate={{ rotate: [-5, 10, 20, 10, -5] }}
                    transition={{ duration: 20, repeat: Infinity }}>
                    <animated.div class="line" id="line1"></animated.div>
                </motion.div>
                <motion.div
                    animate={{ rotate: [-5, 10, 20, 10, -5] }}
                    transition={{ duration: 50, repeat: Infinity }}>
                    <animated.div class="line" id="line2"></animated.div>
                </motion.div>
                <motion.div
                    animate={{ rotate: [-40, -30, -20, -30, -40] }}
                    transition={{ duration: 80, repeat: Infinity }}>
                    <animated.div class="line" id="line3"></animated.div>
                </motion.div>

            </div>
            
            <div class='content' onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
                <animated.div class="typewriter2" style={{ transform: props.xy.interpolate(trans4) }}>
                    <h1>c<b>o</b>de<b>;</b></h1>
                </animated.div>
            
                <animated.div style={{ transform: props.xy.interpolate(trans4) }}>
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        transition={{
                        delay: 1, 
                        type: "spring", stiffness: 100,
                        default: { duration: 1 },
                        damping: 20,}}
                        variants={variants}>
                        
                        <h3 style={{opacity: '0.8'}}>func <b>info</b> () &nbsp;&nbsp;&#123;</h3>
                            
                            <h5 style={{paddingLeft: '10vw', marginLeft: '5px', width:'80vw'}}> 
                            Coding is <b>my passion</b>. I love to code and learn the latest advancements in the coding world and have taken the 
                            initiative to learn how to create applications on as many platforms as possible.</h5>
                            
                            
                        
                        <h3 style={{opacity: '0.8'}}>&#125;</h3>
                    </motion.div>
                    
                    <br/><br/><br/>

                    <motion.div
                        initial="hidden"
                        animate="visible"
                        transition={{
                        delay: 1.5, 
                        type: "spring", stiffness: 100,
                        default: { duration: 1 },
                        damping: 20,}}
                        variants={variants}>
                        
                        <div className="codeFont"><h2 onMouseOver={showFirstAid} onMouseLeave={hideFirstAid}><span>fi<b>r</b>st a<b>i</b>d</span></h2><p class="hide">─ ios</p></div>
                        <div className="codeFont"><h2 onMouseOver={showWaiver} onMouseLeave={hideWaiver}><span>waiv<b>e</b>r</span></h2><p class="hide">─ ios</p></div>
                        <div className="codeFont"><h2 onMouseOver={showCovid} onMouseLeave={hideCovid}><span>cov<b>i</b>d si<b>g</b>n-in</span></h2><p class="hide">─ web</p></div>

                    </motion.div>

                    <div className="codeVidContainer" id="firstAidVid">
                        <video className="codeVid" autoPlay playsInline muted src={testVid} fluid={false} width={600} height={400} loop={true}/>
                    </div>
                    <div className="codeVidContainer" id="waiverVid">
                        <video className="codeVid" autoPlay playsInline muted src={testVid} fluid={false} width={600} height={400} loop={true}/>
                    </div>
                    <div className="codeVidContainer" id="covidVid">
                        <video className="codeVid" autoPlay playsInline muted src={testVid} fluid={false} width={600} height={400} loop={true}/>
                    </div>


                </animated.div>


                <div style={{height: '300px', width: '200px'}}/>

                <MouseTooltip
                    visible={visible}
                    offsetX={50}
                    offsetY={-290}
                    className="followMouse-code">
                    <pre>
                        /*<br/>
                         &nbsp;*  swift<br/>
                         &nbsp;*  built for iPad<br/>
                         &nbsp;*  2017<br/>
                         */<br/>
                         <br/><br/>
                         Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br/>
                         sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br/>
                         Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris <br/>
                         nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in <br/>
                         reprehenderit in voluptate velit esse cillum dolore eu fugiat <br/>
                         nulla pariatur. Excepteur sint occaecat cupidatat non proident, <br/>
                         sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </pre>
                </MouseTooltip>
                
                <MouseTooltip
                    visible={waiverVisible}
                    offsetX={50}
                    offsetY={-290}
                    className="followMouse-code waiver">
                    <pre>
                        /*<br/>
                         &nbsp;*  swift<br/>
                         &nbsp;*  built for iPad<br/>
                         &nbsp;*  2017<br/>
                         */<br/>
                         <br/><br/>
                         Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br/>
                         sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br/>
                         Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris <br/>
                         nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in <br/>
                         reprehenderit in voluptate velit esse cillum dolore eu fugiat <br/>
                         nulla pariatur. Excepteur sint occaecat cupidatat non proident, <br/>
                         sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </pre>
                </MouseTooltip>
                
                <MouseTooltip
                    visible={covidVisible}
                    offsetX={50}
                    offsetY={-290}
                    className="followMouse-code covid">
                    <pre>
                        /*<br/>
                         &nbsp;*  django/python<br/>
                         &nbsp;*  built for Web<br/>
                         &nbsp;*  2020<br/>
                         */<br/>
                         <br/><br/>
                         Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br/>
                         sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br/>
                         Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris <br/>
                         nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in <br/>
                         reprehenderit in voluptate velit esse cillum dolore eu fugiat <br/>
                         nulla pariatur. Excepteur sint occaecat cupidatat non proident, <br/>
                         sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </pre>
                </MouseTooltip>

            </div>

        </Layout>
    )
}

export default Code;